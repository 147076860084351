<template>
  <div class="details">
    <div class="contains">
    <!-- 头部 -->
      <div class="con_top">
        <div class="con_top_left">
          <img
            style="width: 360px; height: 360px"
            src="@/assets/images/record/car.jpg"
          />
        </div>
        <div class="con_top_right">
          <h2>车联网网络安全防护定级备案</h2>
          <p class="new_tit">
            严格按照车联网网络安全防护相关标准，从网络安全专业角度帮助智能网联汽车生产企业、车联网服务平台运营企业开展网络安全防护定级备案、符合性测评、安全风险评估等工作。
          </p>
          <div class="pri_box">
            <p class="title">
              产品价格<i>￥</i><span class="price">{{price}}</span>
            </p>
          </div>
          <div class="price_box">
            <div class="box_left">
              <p>说</p>
              <p>明</p>
            </div>
            <div class="box_right">
              <p>
                从网络安全专业角度帮助智能网联汽车生产企业、车联网服务平台运营企业开展网络安全防护定级备案、符合性测评、安全风险评估等工作，协助企业依法依规开展业务。
              </p>
            </div>
          </div>

          <div class="price_box">
            <div class="box_left">
              <p>分</p>
              <p>类</p>
            </div>
            <div class="box_right">
              <ul>
                <li @click="price = 20000">车联网网络安全防护定级备案（二级）</li>
                <li @click="price = 30000">车联网网络安全防护定级备案（三级）</li>
                <li @click="price = 40000">车联网网络安全防护定级备案（四级）</li>
              </ul>
            </div>
          </div>

          <div class="box_ti">
            <div class="ti_left">
              <p>数</p>
              <p>量</p>
            </div>
            <input class="numbers" value="1" type="text" name="" />
          </div>

          <div class="box_bottom">
            <!-- <a style="pointer-events: none;background:gray; " href="">立即购买</a> -->
            <a
              href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203"
              >咨询专家</a
            >
          </div>
        </div>
      </div>

      <div class="conten_body">
        <div class="conten_left">
          <h3>车联网网络安全防护定级备案</h3>

          <div class="scenario">
            <h4>应用场景</h4>
            <div class="scenario_content">
              <div class="lists">
                <ul>
                  <li>智能网联汽车生产企业、</li>
                  <li>车联网服务平台运营企业的网络设施</li>
                  <li>和系统需要落实网络安全防护定级工作并</li>
                  <li>向通信管理局备案。</li>
                </ul>
              </div>
              <div class="image">
                <img src="@/assets/images/record/aly.jpg" alt="" />
              </div>
            </div>
          </div>

          <div class="because">
            <h4>为什么要开展业务？</h4>
            <div class="because_content">
              <ul>
                <li>
                  <div class="image">
                    <img src="@/assets/images/record/1.jpg" alt="" />
                  </div>
                  <div class="text">
                    <p>协助合法合规的开展经营活动</p>
                  </div>
                </li>
                <li>
                  <div class="image">
                    <img src="@/assets/images/record/2.jpg" alt="" />
                  </div>
                  <div class="text">
                    <p>协助完成网络安全防护定级备案工作</p>
                  </div>
                </li>
                <li>
                  <div class="image">
                    <img src="@/assets/images/record/3.jpg" alt="" />
                  </div>
                  <div class="text">
                    <p>为提供符合性评测和安全风险评估</p>
                  </div>
                </li>
                <li>
                  <div class="image">
                    <img src="@/assets/images/record/4.jpg" alt="" />
                  </div>
                  <div class="text">
                    <p>为提供整改服务及产品建设建议</p>
                  </div>
                </li>
                <li>
                  <div class="image">
                    <img src="@/assets/images/record/5.jpg" alt="" />
                  </div>
                  <div class="text">
                    <p>满足车联网相关网络安全防护标准</p>
                  </div>
                </li>
                <li>
                  <div class="image">
                    <img src="@/assets/images/record/6.jpg" alt="" />
                  </div>
                  <div class="text" style="width:180px">
                    <p>加强车联网网络设施和网络系统安全防护能力</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="detailed">
              <h4>产品详情</h4>
              <div class="detailed_content">
                  <div class="content_one">
                      <h5>定级备案评估依据</h5>
                      <ul>
                          <li> 1.《中华人民共和国网络安全法》</li>
                          <li> 2.《中华人民共和国数据安全法》</li>
                          <li> 3.《中华人民共和国个人信息保护法》</li>
                          <li> 4.《中华人民共和国电信条例》</li>
                          <li> 5.《通信网络安全防护管理办法》（工信部第11 号令）</li>
                          <li> 6.《电信网和互联网网络安全防护系列技术标  准》</li>
                          <li> 7.《车联网网络安全防护定级备案实施指南》</li>
                          <li> 8《移动互联网联网应用安全防护要求》</li>
                          <li> 9.《移动互联网联网应用安全防护检测要求》</li>
                          <li> 10.《电信网和互联网安全防护基线配置要求 网络设备（YD/T 2698-2014）</li>
                          <li> 11.《电信网和互联网安全防护基线配置要求 安全设备（YD/T 2699-2014）》</li>
                          <li> 12.《电信网和互联网安全防护基线配置要求 数据库（YD/T 2700-2014）》</li>
                          <li> 13.《电信网和互联网安全防护基线配置要求 操作系统（YD/T 2701-2014）》</li>
                          <li> 14.《电信网和互联网安全防护基线配置要求 中间件（YD/T 2702-2014）》</li>
                          <li> 15.《电信网和互联网安全防护基线配置要求  WEB应用系统（YD/T 2703-2014）》</li>
                          <li> 16.......</li>
                      </ul>
                  </div>
                  <div class="content_one">
                      <h5>交付成果</h5>
                      <ul>
                          <li>  1.定级报告 </li>
                          <li>  2.符合性测评报告 </li>
                          <li>  3.风险评估报告 </li>
                          <li>  4.符合性测评证明文件 </li>
                          <li>  5.风险评估证明文件 </li>
                          <li>  6.其他管局要求的文件</li>
                      </ul>
                  </div>
                  <div class="content_two">
                      <h5>服务流程</h5>
                      <p>签订合同→信息基本情况调查→测评实施（符合性测评、漏洞扫描、渗透测试、专家分析、风险评估、问题清单及整改建议等）→客户整改→复评→出具报告</p>
                  </div>
                  <div class="content_two">
                      <h5>时间周期</h5>
                      <p style="height:18px;left:5px">    甲方提供完整调查信息后的1周内完成。</p>
                  </div>
              </div>
          </div>

          <div class="advantage">
              <h4>万方优势</h4>
              <div class="advantage_content">
                  <div class="left">
                      <ul>
                          <li>1.国家信息安全服务资质(安全工程类)</li>
                          <li>2.国家信息安全风险评估资质</li>
                          <li>3.国家通信网络安全服务风险评估资质</li>
                          <li>4.国家信息安全应急处理服务资质</li>
                          <li>5.国家信息系统安全集成服务资质</li>
                          <li>6.国家信息安全漏洞库支持单位</li>
                          <li>7.国际信息安全管理体系ISO 27001认证</li>
                          <li>8.国际质量管理体系ISO9001认证</li>
                          <li>9.国际环境管理体系ISO 14001认证</li>
                          <li>10.高新技术企业</li>
                          <li>11.软件企业</li>
                          <li>12.企业研究开发机构证书</li>
                      </ul>
                  </div>
                  <div class="right">
                      <img src="@/assets/images/record/qualifications.jpg" alt="">
                  </div>
              </div>
              <div class="honor">
                  <ul>
                      <li>1.安全博士领衔多位获得国际和国内安全资质的安全专家为您服务。</li>
                      <li>2.专注网络信息安全领域10年以上，熟悉监管部门对政策法规的具体落地要求。</li>
                      <li>3.公司资质：具备国家网络信息安全主管部门颁发的权威安全认证资质及国际权威认证资质。</li>
                  </ul>
              </div>
          </div>
        </div>

        <div class="conten_right">
                <div class="phone">
                    <div class="phone_left">
                        <p>服务热线(微信同号)：</p>
                        <p>188 188 60088</p> 
                        <p>188 188 63388</p>
                    </div>
                    <div class="phone_right">
                        <img src="@/assets/images/app/code.png" alt="">
                    </div>
                </div>
                <div class="hot">
                    <div class="hot_title">
                        <h4>热门产品</h4>
                    </div>
                        <div class="hot_lists">
                            <ul>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/app1">
                                            <img src="@/assets/images/app/001.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>App安全合规基础版</p>
                                        <p>依据国家相关法律规定</p>
                                        <p>￥4800</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/app3">
                                            <img src="@/assets/images/app/003.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>App安全无忧版</p>
                                        <p>依据国家相关法律规定</p>
                                        <p>￥69800</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                </div>
            </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      price:"20000-40000"
    }
  }
};
</script>

<style type="text/css" lang="less" scoped>
h3 {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
.scenario {
  width: 880px;
  height: 307px;
  margin-top: 59px;
  box-shadow: 5px 4px 7px #e2e2e2;
  border-top: 1px solid #e2e2e2;
  h4 {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-top: 38px;
  }
  .scenario_content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 18px;
    .lists {
      ul {
        li {
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
    .image {
        height: 207px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.because {
  margin-top: 28px;
  width: 880px;
  height: 792px;
  box-shadow: 5px 4px 7px #e2e2e2;
  border-top: 1px solid #e2e2e2;
  h4 {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-top: 38px;
  }
  .because_content {
    margin-top: 73px;
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-around;
      li {
        display: flex;
        flex-direction: column;
        margin-bottom: 80px;
        align-items: center;
        width: 280px;
        .image {
          width: 150px;
          height: 150px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .text {
          margin-top: 50px;
          width: 150px;
          text-align: center;
          p {
            font-size: 18px;
            line-height: 20px;
          }
        }
        
      }
    }
  }
}

.detailed{
    width: 880px;
    height: 1000px;
    margin-top: 28px;
    box-shadow: 5px 4px 7px #e2e2e2;
    border-top: 1px solid #e2e2e2;
  h4 {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-top: 38px;
  }
  .detailed_content{
      margin-top: 66px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: flex-start;
      .content_one{
          width: 353px;
          height: 561px;
          border: 1px solid #086E84;
          h5{
              width: 100%;
              text-align: center;
              font-size: 18px;
              line-height: 30px;
              color: white;
              background-color: #07505F;
          }
          ul{
              height: 531px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-right:5px;
              li{
                  text-indent: 1rem;
                  font-size: 16px;
                  line-height: 18px;
              }
          }
      }
      .content_two{
          width: 353px;
          height: 250px;
          border: 1px solid #086E84;
          position: relative;
          margin-top: 41px;
          h5{
              width: 100%;
              text-align: center;
              font-size: 18px;
              line-height: 30px;
              color: white;
              background-color: #07505F;
          }
          p{
              display: block;
              width: 100%;
              height: 72px;
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
              font-size: 16px;
              line-height: 18px;
          }
      }
  }
}

.advantage{
    width: 880px;
    height: 536px;
    margin-top: 28px;
    box-shadow: 5px 4px 7px #e2e2e2;
    border-top: 1px solid #e2e2e2;
    margin-bottom: 5px;
    padding-bottom: 20px;
  h4 {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-top: 38px;
  }
  .advantage_content{
      width: 736px;
      height: 330px;
      margin-top: 32px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border: 3px solid #096668;
     margin-left: 74px;
      .left{
          ul{
              li{
                  font-size: 16px;
                  line-height: 18px;
              }
          }
      }
      .right{
          width: 292px;
          height: 280px;
          img{
              width: 100%;
              height: 100%;
          }
      }
  }
  .honor{
      margin-top: 41px;
      margin-left: 74px;
      ul{
          li{
              font-size: 18px;
              line-height: 20px;
          }
      }
  }
}

.conten_right{
	width:300px;
	background:#f4f4f4;
	float:left;
  display: block;
  min-height: 100%;
	margin-left:20px;
    .phone{
        width: 100%;
        height: 100px;
        background-color: #00A9F2;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .phone_left{
            p{
                font-size: 16px;
                line-height: 18px;
                color: white;
            }
        }
        .phone_right{
            width: 75px;
            height: 75px;
            img{
                width:100%;
                height: 100%;
                }
        }
    }
    .hot{
        margin-top: 30px;
        background-color: #F2F2F2;
        height: 380px;
        .hot_title{
            width: 300px;
            height: 35px;
            background: #00A9F2;
            h4{
                margin-left: 20px;
                font-size: 18px;
                line-height: 35px;
                color: white;
            }
        }
        .hot_lists{
            ul{
                li{
                    display: flex;
                    margin-top: 20px;
                    margin-bottom: 50px;
                    .hot_left{
                        width: 147px;
                        height: 116px;
                        img{
                            width:147px;
                            height:116px;
                        }
                    }
                    .hot_right{
                        width: 140px;
                        margin-left: 20px;
                        p{
                            font-size: 14px;
                            line-height: 25px;
                             white-space: nowrap;    
                            overflow: hidden;         
                            text-overflow: ellipsis;
                        }
                        :nth-child(1){
                            padding-right: 25px;
                            font-weight: bold;
                           
                        }
                        :nth-child(2){
                             padding-right: 48px;
                            font-size: 13px;
                             
                        }
                         :nth-child(3){
                            font-size: 16px;
                            color: #B20909;
                           
                        }
                    }
                }
            }
        }
    }

}

.details {
  width: 1200px;
  margin: 0 auto;
}
.contains {
  margin-top: 20px;
  padding: 20px 0px;
  background: #fff;
}
.con_top {
  display: flex;
  overflow: hidden;
}
.con_top_left {
  width: 358px;
  margin-right: 75px;
}

.con_top_right {
  width: 790px;
}
.con_top_right h2 {
  font-size: 25px;
  color: #000000;
  font-weight: bold;
  line-height: 50px;
}
.new_tit {
  background-color: #00a9f2;
  height: 45px;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  padding-top: 11px;
}
.pri_box {
  // background:url('../assets/images/b03.png');
  padding-top: 10px;
  box-sizing: border-box;
}
.pri_box .title {
  font-size: 18px;
  color: #000000;
}
.pri_box i {
  font-size: 23px;
  font-weight: 400;
  color: #ff0000;
  line-height: 47px;
  padding-left: 45px;
  padding-right: 10px;
}
.pri_box .price {
  font-size: 35px;
  font-weight: bold;
  color: #ff0000;
  position: relative;
  top: 3px;
}
.price_box {
  display: flex;
  align-items: flex-start;
  margin-top: 15px;
  .box_left {
    width: 105px;
    height: 24px;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
    p {
      display: inline-block;
      font-size: 18px;
      line-height: 18px;
      color: #000000;
      width: 64px;
    }
  }
  .box_right {
    p {
      font-size: 14px;
      line-height: 16px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        font-size: 14px;
        margin-right: 30px;
        line-height: 30px;
        color: #333333;
        background-color: #f2f2f2;
        padding: 0 5px;
        margin-right: 50px;
        margin-bottom: 10px;
      }
      :hover{
        cursor:pointer
      }
    }
  }
}
.box_ti {
  margin-top: 25px;
  display: flex;
  align-items: flex-start;
}
.box_ti .ti_left {
  display: flex;
  justify-content: space-between;
  width: 70px;
  padding-right: 50px;
}
.ti_left p {
  font-size: 18px;
  color: #000000;
  line-height: 18px;
}
.box_ti .numbers {
  width: 69px;
  height: 25px;
  border: 1px solid #000000;
  font-size: 13px;
  font-weight: 300;
  color: #000000;
  line-height: 30px;
  padding-left: 6px;
}
.box_bottom {
  margin-top: 10px;
}
.box_bottom a {
  width: 162px;
  height: 47px;
  background: #d97602;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  line-height: 47px;
  margin-right: 9px;
}
.box_bottom a:nth-of-type(2) {
  color: #fff;
  background: #ff7f02;
  border: 1px solid #ff7f02;
}

.conten_body {
  margin-top: 10px;
  overflow: hidden;
}
.conten_left {
  width: 880px;
  float: left;
  background: #fff;
  box-sizing: border-box;
  margin-top: 50px;
}
</style>
